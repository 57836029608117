<template>
    <div class="manage_project wrapper">
        <proTitle></proTitle>
        <el-form :inline="true" :model="formInline" class="demo-form-inline flex-align-between">
            <div class="formInline-left">
                <el-form-item label="" class="search">
                    <el-input @input="filterCourse()" style="width:316px;" v-model="formInline.search"
                        suffix-icon="el-icon-search" placeholder="请输入问卷名称" size="mini"></el-input>
                </el-form-item>
            </div>
            <div class="formInline-right">
                <i class="el-icon-circle-plus-outline"></i>
                <span class="csp"
                    @click="$router.push(`/salon/create2/${$store.state.user.currentProject.id}?breadNumber=4`)">创建问卷</span>
            </div>
        </el-form>
        <div class="mid-bot">
            <el-table :data="tableData">
                <el-table-column type="index" label="序号" align="center"></el-table-column>
                <el-table-column prop="title" label="问卷名称" width="160px" align="center"></el-table-column>
                <el-table-column label="时间" width="200px" align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.row.beginTime }} - {{ scope.row.endTime }} </span>
                    </template>
                </el-table-column>
                <el-table-column label="发布状态" width="100px" align="center">
                    <template slot-scope="scope">
                        <span>{{ statusList[scope.row.status] }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="answerTotal" label="提交数量" width="100px" align="center"></el-table-column>
                <el-table-column prop="type" label="操作" fixed="right" width="180px" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" @click="handleWjx(1, scope.row.activityId)">设计问卷</el-button>
                        <el-button type="text" size="small" @click="edit(scope.row.id)">编辑问卷</el-button>
                        <br />
                        <el-button type="text" size="small" @click="handleWjx(2, scope.row.activityId)">发布</el-button>
                        <el-button type="text" size="small" @click="handleWjx(3, scope.row.activityId)">分析下载</el-button>
                        <br />
                        <el-button type="text" size="small"
                            @click="handleWjx(4, scope.row.activityId, scope.row.id)">同步数据</el-button>
                        <el-button type="text" size="small" @click="isdelete(scope.row.id)">删除</el-button>
                        <el-button type="text" size="small" slot="reference" @click="showQRCode(scope.row)">二维码</el-button>
                        <br />
                        <el-button type="text" size="small"
                            @click="handleWjx(5, scope.row.activityId, scope.row.id)">生成文件</el-button>
                        <el-button type="text" size="small"
                            @click="handleWjx(6, scope.row.activityId, scope.row.id)">下载</el-button>
                        <el-button type="text" size="small"
                            @click="handleWjx(7, scope.row.activityId, scope.row.id)">报告</el-button>
                        <!-- <el-popover placement="top-start" width="150" trigger="hover" style="margin-left: 10px;">
                            <img :src="scope.row.qrcode" alt="" style="width: 150px;height: 150px;">
                            <el-button type="text" size="small" slot="reference">二维码</el-button>
                        </el-popover> -->
                    </template>
                </el-table-column>
            </el-table>
            <pagination v-show="total > 0" :total="total" :page.sync="page" :limit.sync="limit" :pageSizes="[5, 10, 15]"
                @pagination="handlePageChange">
            </pagination>
        </div>
        <!-- 二维码弹窗 -->
        <el-dialog :visible.sync="dialogVisible" width="35%">
            <div class="qrBox flex-align-between">
                <div class="qrcodeItem flex-column-center" v-for="(item, index) in qrcodeList" :key="index">
                    <span class="name" v-if="item.title">{{ item.title }}</span>
                    <span class="url">地址:{{ item.url }}</span>
                    <el-image style="width: 150px; height: 150px" :src="item.qrcode">
                    </el-image>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
  
<script>
import QRCode from 'qrcode'
export default {
    data() {
        return {
            statusList: {
                0: '未发布',
                1: '已发布',
                2: '已暂停',
                3: '已删除',
                4: '彻底删除',
                5: '被审核'
            },
            formInline: { //搜索栏表单数据
                search: "", //模糊查询
            },
            tableData: [],
            page: 1,
            limit: 10,
            total: 0,
            dialogVisible: false,
            srcList: [],
            qrcodeList: []
        };
    },
    methods: {
        // 翻页
        handlePageChange(data) {
            this.page = data.pageIndex
            this.limit = data.pageSize
            this.getWjxList();
        },
        // 删除提示
        isdelete(id) {
            this.$confirm("确定删除？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                let data = {
                    id
                }
                this.$Api.wjx.deleteQuest(data)
                    .then(res => {
                        // console.log('删除成功:',res);
                        this.getWjxList()
                    })
                    .catch(err => {
                        console.log('删除失败:', err)
                    })
            })
                .catch((err) => {
                    console.log('err:', err)
                    this.$message({
                        type: "info",
                        message: "已取消删除"
                    });
                });
        },
        //筛选课程 [包括：筛选课程类型，模糊查询课程]
        filterCourse() {
            this.page = 1;
            this.getWjxList();
        },
        // 获取问卷星问卷列表
        async getWjxList() {
            let data = {
                projectId: this.$store.state.user.currentProject.id, //当前项目id
                page: this.page,
                limit: this.limit,
                queryKey: this.formInline.search
            }
            let resData;
            if (this.$store.state.user.role == 'expert') {
                resData = await this.$Api.wjx.listExpert(data);
            } else {
                resData = await this.$Api.wjx.listAdmin(data);
            }
            // console.log('问卷星列表:', resData);
            // resData.data.records.map(item => {
            //     if (item.qrUrl) {
            //         this.setQRCode(item.qrUrl)
            //             .then(res => {
            //                 item.qrcode = res
            //             })
            //     }

            // })
            this.tableData = resData.data.records;
            this.total = resData.data.total;

        },
        // 操作
        handleWjx(type, activityId, id) {
            // type: 1-设计问卷 2-发布 3-分析下载
            let url;
            switch (type) {
                case 1:
                    url = `https://www.wjx.cn/wjx/design/designstart.aspx?activity=${activityId}`;
                    break;
                case 2:
                    url = `https://www.wjx.cn/newwjx/design/sendqstart.aspx?activityid=${activityId}`;
                    break;
                case 3:
                    url = `https://www.wjx.cn/report/${activityId}.aspx?sat=1`;
                    break;
                case 4:
                    let params = {
                        id
                    }
                    this.$Api.wjx.syncWjx(params)
                        .then(res => {
                            this.$message({
                                message: '同步数据成功',
                                type: 'success'
                            });
                            this.getWjxList();
                        })
                        .catch(err => {
                            console.log('获取同步数据连接err:', err);
                            this.$message.error('同步数据失败');
                        })
                    break
                case 5:
                    // console.log('生成文件');
                    let params2 = {
                        vid: activityId
                    }
                    this.$Api.wjx.create(params2)
                        .then(res => {
                            //   console.log('生成文件成功:',res);
                            if (res.code == 200) {
                                this.$message({
                                    message: '生成文件成功',
                                    type: 'success'
                                });
                            }
                        })
                        .catch(err => {
                            // console.log('生成文件err:', err);
                            this.$message.error('生成文件失败');
                        })
                    break
                case 6:
                    // console.log('下载')
                    let params3 = {
                        vid: activityId
                    }
                    this.$Api.wjx.checkStatus(params3)
                        .then(res => {
                            //   console.log('检查状态成功:',res);
                            if (!res.data.state) {
                                this.$message.error('下载失败请重试');
                            } else {
                                window.open(this.downloadURL + res.data.identity)
                            }
                        })
                        .catch(err => {
                            // console.log('检查状态err:', err);
                            this.$message.error('下载失败请重试');
                        })
                    break;

                case 7:
                    this.$router.push(`/WjxReport?breadNumber=4&vid=${activityId}`)
            };
            if (type == 4 || type == 5 || type == 6 || type == 7) { return }
            let data = {
                url
            }
            this.$Api.wjx.getLoginUrl(data)
                .then(res => {
                    console.log('获取操作url:', res);
                    this.$router.push({
                        path: '/salon/wjxIframe',
                        query: {
                            breadNumber: 5,
                            iframeUrl: res.data,
                            breadcrumbName: type == 1 ? '设计问卷' : type == 2 ? '发布' : '分析下载'
                        }
                    })
                })
                .catch(err => {
                    console.log('获取操作url失败:', err)
                })
        },
        // 生成二维码
        setQRCode(url) {
            if (!url) {
                return
            }
            return QRCode.toDataURL(url, {
                with: 150,
                margin: 1
            }).then(res => {
                console.log('生成二维码:', res);
                return res
            }).catch(err => {
                console.log('生成二维码失败:', err)
            })
        },
        // 编辑问卷
        edit(id) {
            this.$router.push(`/salon/create2/${this.$store.state.user.currentProject.id}?breadNumber=4&type=edit&id=${id}`)
        },
        showQRCode(item) {
            this.qrcodeList = [];
            this.dialogVisible = true;
            console.log('item:', item);
            if (!item.yxIsBind && !item.yzIsBind) {
                this.setQRCode(item.qrUrl)
                    .then(res => {
                        let obj = {
                            url: item.qrUrl,
                            qrcode: res
                        };
                        this.qrcodeList.push(obj)
                    })
                return
            };
            if (item.yxIsBind && item.yxQrUrl) {
                this.setQRCode(item.yxQrUrl)
                    .then(res => {
                        let obj = {
                            title: '双优云桥',
                            url: item.yxQrUrl,
                            qrcode: res
                        };
                        this.qrcodeList.push(obj)
                    })
            };
            if (item.yzIsBind && item.yzQrUrl) {
                this.setQRCode(item.yzQrUrl)
                    .then(res => {
                        let obj = {
                            title: '首师优字',
                            url: item.yzQrUrl,
                            qrcode: res
                        };
                        this.qrcodeList.push(obj)
                    })
            };
        }
    },
    mounted() {
        this.getWjxList();
    }
};
</script>
  
<style lang="less" scoped>
@import "../less/table.less";

.mid-bot {
    margin-top: 20px;
}

.qrBox {
    width: 100%;

    .qrcodeItem {
        .name {
            font-size: 16px;
            font-weight: bold;
            color: #333;
        }

        .url {
            width: 250px;
            display: block;
            margin: 10px 0;
        }
    }
}

/deep/ .el-dialog__body {
    padding: 0 20px 50px;
}</style>